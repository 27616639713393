import React, { useCallback } from "react";
import { DialogCta } from "../DialogCta";
import { Trans, useTranslation } from "react-i18next";
import { DiscPreset } from "../../model/discs";
import { Box, Button } from "@mui/material";
import { Medium } from "../Text";
import { toast } from "sonner";

// Replace this import with your actual function to copy a disc preset to global
import { copyDiscPresetToGlobal } from "../discs/actions";

export default function CopyDiscToGlobalDialog(props: {
  userId: string;
  anchor: React.ComponentType<{ children: React.ReactNode; onClick: () => void }>;
  discPreset?: DiscPreset;
  onCopy?: () => void;
}) {
  const { userId, discPreset, anchor, onCopy } = props;
  const { t } = useTranslation();

  const handleCopy = useCallback(
    (close: () => void) => () => {
      if (discPreset && discPreset.id) {
        // Call your actual copy logic here:
        copyDiscPresetToGlobal(userId, discPreset.id);
        close();

        toast.info(() => (
          <Trans
            style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
            i18nKey="discPreset.notification.copied"
            values={{ discName: discPreset.name }}
            components={{ 0: <b /> }}
          />
        ));

        onCopy?.();
      }
    },
    [discPreset, onCopy, userId],
  );

  return discPreset ? (
    <DialogCta
      title={t("copyingItem", { item: t("disc") })}
      anchor={anchor}
      actions={({ close }) => (
        <>
          <Button variant="secondary" onClick={close}>
            {t("nevermind")}
          </Button>
          <Button variant="primary" onClick={handleCopy(close)}>
            {t("confirm.copy.yes", { name: discPreset?.name })}
          </Button>
        </>
      )}
    >
      <Medium>
        <Trans
          i18nKey="confirm.copy.item"
          values={{ item: discPreset?.name }}
          components={{
            0: <Box sx={{ fontWeight: 500 }} />,
            1: <p />,
          }}
        />
      </Medium>
    </DialogCta>
  ) : null;
}
