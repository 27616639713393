import { PrimaryThrowType } from "@/dashboard/DashboardTags";
import { getUserThrowSummaries } from "@/firebase/collections";
import { useUser } from "@/hooks/useUser";
import type { ThrowSummary } from "@/model/throwSummary";
import { Handedness } from "@/model/UserSettings";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Card, Container, Skeleton, Stack, Typography } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import { format, subDays, subMonths } from "date-fns";
import {
  count,
  getAggregateFromServer,
  getCountFromServer,
  getDocs,
  query,
  sum,
  Timestamp,
  where,
} from "firebase/firestore";
import { AnimatePresence, motion, type Variants } from "framer-motion";
import { useEffect, useReducer, useState, type Reducer } from "react";

type OverviewStats = {
  total: number;
  lh: number;
  rh: number;
  totalDistance: number;
  lastSevenDaysTotal: number;
  previousSevenDaysTotal: number;
  previousSevenDaysThrows: ThrowSummary[];
  lastSevenDaysThrows: ThrowSummary[];
  forehandTotal: number;
  backhandTotal: number;
  previousWeek: Date[];
  lastWeek: Date[];
  lastSixMonthsThrows: ThrowSummary[];
  lastSixMonthsTotal: number;
  lastSixMonths: Date[];
};

type OverviewAction = {
  type: "update";
  payload: OverviewStats;
};
const trends: Trend[] = [
  {
    trend: "none",
    colors: {
      primary: "#9ca3af",
      muted: "#fafafa",
    },
  },
  {
    trend: "flat",
    colors: {
      primary: "#2563eb",
      muted: "#f0f9ff",
    },
  },
  {
    trend: "upward",
    colors: {
      primary: "#10b981",
      muted: "#ecfdf5",
    },
  },
  {
    trend: "downward",
    colors: {
      primary: "#8b5cf6",
      muted: "#ede9fe",
    },
  },
];

const variant1: Variants = {
  initial: {
    opacity: 0,
    y: -15,
    scale: 0.9,
  },
  hidden: {
    opacity: 0,
    y: 15,
    scale: 1,
  },
  animate: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
      duration: 0.2,
      staggerChildren: 0.5,
    },
  },
};

const groupThrowsByDate = (throws: ThrowSummary[], dateFormat: string) => {
  return throws.reduce(
    (acc, throwData) => {
      const date = format(throwData.throwTime.toDate(), dateFormat);
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += 1;
      return acc;
    },
    {} as Record<string, number>,
  );
};
// Prepare data for the chart
const prepareChartData = <T extends ThrowSummary>(
  data: T[],
  dates: Date[],
  dateFormat: string,
): { date: string; count: number }[] => {
  const groupedData = groupThrowsByDate(data, dateFormat);
  return dates.map((date) => ({
    date: format(date, dateFormat),
    count: groupedData[format(date, dateFormat)] ?? 0,
  }));
};

export default function OverviewRoute() {
  const [{ userId, userSettings }] = useUser();
  const [stats, dispatch] = useReducer<Reducer<OverviewStats, OverviewAction>>(
    (state: OverviewStats, action: OverviewAction) => {
      return action.payload;
    },
    {
      total: 0,
      lh: 0,
      rh: 0,
      totalDistance: 0,
      lastSevenDaysTotal: 0,
      previousSevenDaysTotal: 0,
      previousSevenDaysThrows: [],
      lastSevenDaysThrows: [],
      lastSixMonthsThrows: [],
      lastSixMonthsTotal: 0,
      forehandTotal: 0,
      backhandTotal: 0,
      previousWeek: [],
      lastWeek: [],
      lastSixMonths: [],
    },
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const compileStats = async () => {
      const totalSnapshot = await getAggregateFromServer(getUserThrowSummaries(userId), {
        totalThrows: count(),
        totalDistance: sum("estimatedFeet"),
      });
      const total = totalSnapshot.data()?.totalThrows;
      const totalDistance = totalSnapshot.data()?.totalDistance;

      const lastSevenDaysStart = subDays(new Date(), 7);
      const previousSevenDaysStart = subDays(lastSevenDaysStart, 7);

      const lastSixMonths = Array.from({ length: 6 }).map((_, index) => {
        return subMonths(new Date(), 6 - index);
      });
      const lastWeek = Array.from({ length: 7 }).map((_, index) => {
        return subDays(new Date(), 7 - index);
      });
      const previousWeek = Array.from({ length: 7 }).map((_, index) => {
        return subDays(lastSevenDaysStart, 7 - index);
      });

      const lastSixMonthsSnapshot = await getDocs(
        query(
          getUserThrowSummaries(userId),
          where("throwTime", ">", Timestamp.fromDate(lastSixMonths[0])),
        ),
      );
      const lastSixMonthsThrows = lastSixMonthsSnapshot.docs.map((doc) => doc.data());
      const lastSixMonthsTotal = lastSixMonthsThrows.length;

      const lastSevenDaysSnapshot = await getDocs(
        query(
          getUserThrowSummaries(userId),
          where("throwTime", ">", Timestamp.fromDate(lastSevenDaysStart)),
        ),
      );
      const lastSevenDaysThrows = lastSevenDaysSnapshot.docs.map((doc) => doc.data());
      const lastSevenDaysTotal = lastSevenDaysThrows.length;

      const previousSevenDaysSnapshot = await getDocs(
        query(
          getUserThrowSummaries(userId),
          where("throwTime", ">", Timestamp.fromDate(previousSevenDaysStart)),
          where("throwTime", "<", Timestamp.fromDate(lastSevenDaysStart)),
        ),
      );

      const previousSevenDaysThrows = previousSevenDaysSnapshot.docs.map((doc) => doc.data());
      const previousSevenDaysTotal = previousSevenDaysThrows.length;

      const leftyThrowsSnapshot = await getCountFromServer(
        query(
          getUserThrowSummaries(userId),
          where("primaryType", "in", [
            PrimaryThrowType.LHBH,
            PrimaryThrowType.LHBHG,
            PrimaryThrowType.LHTHU,
            PrimaryThrowType.LHFH,
            PrimaryThrowType.LHFHG,
            PrimaryThrowType.LHTOM,
          ]),
        ),
      );

      const leftyCount = leftyThrowsSnapshot.data()?.count;

      const forehandThrows = [
        PrimaryThrowType.LHFH,
        PrimaryThrowType.LHFHG,
        PrimaryThrowType.LHTOM,
        PrimaryThrowType.RHFH,
        PrimaryThrowType.RHFHG,
        PrimaryThrowType.RHTOM,
      ];

      const backhandThrows = [
        PrimaryThrowType.LHBH,
        PrimaryThrowType.LHBHG,
        PrimaryThrowType.LHTHU,
        PrimaryThrowType.RHBH,
        PrimaryThrowType.RHBHG,
        PrimaryThrowType.RHTHU,
      ];

      const forehandThrowsSnapshot = await getCountFromServer(
        query(getUserThrowSummaries(userId), where("primaryType", "in", forehandThrows)),
      );

      const backhandThrowsSnapshot = await getCountFromServer(
        query(getUserThrowSummaries(userId), where("primaryType", "in", backhandThrows)),
      );

      const forehandTotal = forehandThrowsSnapshot.data()?.count;
      const backhandTotal = backhandThrowsSnapshot.data()?.count;

      const rightyThrowsSnapshot = await getCountFromServer(
        query(
          getUserThrowSummaries(userId),
          where("primaryType", "in", [
            PrimaryThrowType.RHBH,
            PrimaryThrowType.RHFH,
            PrimaryThrowType.RHBHG,
            PrimaryThrowType.RHFHG,
            PrimaryThrowType.RHTHU,
            PrimaryThrowType.RHTOM,
          ]),
        ),
      );

      const rightyCount = rightyThrowsSnapshot.data()?.count;

      dispatch({
        type: "update",
        payload: {
          total,
          lh: leftyCount,
          rh: rightyCount,
          totalDistance,
          lastSevenDaysTotal,
          previousSevenDaysTotal,
          forehandTotal,
          backhandTotal,
          lastSevenDaysThrows,
          previousSevenDaysThrows,
          previousWeek,
          lastWeek,
          lastSixMonthsThrows,
          lastSixMonthsTotal,
          lastSixMonths,
        },
      });
      setIsLoaded(true);
    };
    compileStats();
  }, [userId]);

  const previousSevenDaysChartData = prepareChartData(
    stats.previousSevenDaysThrows,
    stats.previousWeek,
    "EEE dd",
  );
  const lastSevenDaysChartData = prepareChartData(
    stats.lastSevenDaysThrows,
    stats.lastWeek,
    "EEE dd",
  );

  const lastSixMonthsChartData = prepareChartData(
    stats.lastSixMonthsThrows,
    stats.lastSixMonths,
    "MMM",
  );
  console.log(lastSixMonthsChartData);
  const previousSevenDaysMaxDay = previousSevenDaysChartData.reduce((maxThrows, { count }) => {
    return Math.max(maxThrows, count);
  }, 0);
  const lastSevenDaysMaxDay = lastSevenDaysChartData.reduce((maxThrows, { count }) => {
    return Math.max(maxThrows, count);
  }, 0);

  const lastSevenDaysChange = Math.floor(
    stats.lastSevenDaysTotal * stats.previousSevenDaysTotal === 0
      ? 0
      : ((stats.lastSevenDaysTotal - stats.previousSevenDaysTotal) / stats.previousSevenDaysTotal) *
          100,
  );

  const previousSevenDaysChange = Math.floor(
    stats.lastSevenDaysTotal * stats.previousSevenDaysTotal === 0
      ? 0
      : ((stats.previousSevenDaysTotal - stats.lastSevenDaysTotal) / stats.lastSevenDaysTotal) *
          100,
  );

  const handDominance = userSettings?.handedness;

  return (
    <Container
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          fontSize: "2.2rem",
          color: "grey.700",
          fontWeight: 500,
          letterSpacing: "-0.05rem",
          mb: 4,
        }}
      >
        Activity Overview
      </Box>
      <Stack gap={2}>
        <Box
          sx={{
            fontSize: "1.8rem",
            color: "grey.700",
            fontWeight: 500,
            letterSpacing: "-0.05rem",
          }}
        >
          Throws
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 4,
          }}
        >
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Stack
                direction="row"
                sx={{
                  fontSize: "1.4rem",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    color: "primary.main",
                  }}
                >
                  Forehand
                </Box>
                {stats.forehandTotal ? (
                  <Box sx={{ fontWeight: 500 }}>
                    {Math.floor((stats.forehandTotal / stats.total) * 100)}%
                  </Box>
                ) : null}
              </Stack>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                {isLoaded ? (
                  new Intl.NumberFormat().format(stats.forehandTotal)
                ) : (
                  <Skeleton variant="text" />
                )}
              </Box>
            </Stack>
          </Card>
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Stack
                direction="row"
                sx={{
                  fontSize: "1.4rem",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    color: "primary.main",
                  }}
                >
                  Backhand
                </Box>
                {stats.backhandTotal ? (
                  <Box sx={{ fontWeight: 500 }}>
                    {Math.floor((stats.backhandTotal / stats.total) * 100)}%
                  </Box>
                ) : null}
              </Stack>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                {isLoaded ? (
                  new Intl.NumberFormat().format(stats.backhandTotal)
                ) : (
                  <Skeleton variant="text" />
                )}
              </Box>
            </Stack>
          </Card>
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Stack gap={0.5}>
                <Box sx={{ fontSize: "1.4rem", color: "primary.main" }}>Primary</Box>
                <Typography fontSize={14} color="grey.600">
                  {`${handDominance === Handedness.RIGHT ? "Right Hand" : "Left Hand"}`}
                </Typography>
              </Stack>
              {isLoaded ? (
                <Stack direction="row" alignItems="baseline" gap={0.5}>
                  <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                    {new Intl.NumberFormat().format(
                      handDominance === Handedness.RIGHT ? (stats.rh ?? 0) : (stats.lh ?? 0),
                    )}
                  </Box>
                  <Typography fontSize="1.1rem" color="grey.700">
                    throws
                  </Typography>
                </Stack>
              ) : (
                <Skeleton variant="text" />
              )}
            </Stack>
          </Card>
          <Card sx={{ minWidth: "225px", p: 2 }}>
            {/* <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} xAxis={{ scaleType, data }} /> */}
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Stack gap={0.5}>
                <Box sx={{ fontSize: "1.4rem", color: "primary.main" }}>Secondary</Box>
                <Typography fontSize={14} color="grey.600">
                  {`${handDominance === Handedness.RIGHT ? "Left Hand" : "Right Hand"}`}
                </Typography>
              </Stack>
              {isLoaded ? (
                <Stack direction="row" alignItems="baseline" gap={0.5}>
                  <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                    {new Intl.NumberFormat().format(
                      handDominance === Handedness.RIGHT ? (stats.lh ?? 0) : (stats.rh ?? 0),
                    )}
                  </Box>
                  <Typography fontSize="1.1rem" color="grey.700">
                    throws
                  </Typography>
                </Stack>
              ) : (
                <Skeleton variant="text" />
              )}
            </Stack>
          </Card>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 4,
          }}
        >
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Box sx={{ fontSize: "1.4rem", color: "primary.main" }}>Total</Box>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                {isLoaded ? (
                  new Intl.NumberFormat().format(stats.total)
                ) : (
                  <Skeleton variant="text" />
                )}
              </Box>
              <SparkLineChart
                data={lastSixMonthsChartData.map((data) => data.count)}
                xAxis={{
                  data: lastSixMonthsChartData.map((data) => data.date),
                }}
              />
              <Typography fontSize={12} color="grey.600">
                Last 6 months
              </Typography>
            </Stack>
          </Card>
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack sx={{ fontSize: "1.4rem", color: "primary.main" }}>
                  Previous 7 Days
                  <Box sx={{ fontSize: "0.8rem", color: "grey.600" }}>
                    {format(subDays(subDays(new Date(), 7), 7), "MMM d")} -{" "}
                    {format(subDays(new Date(), 7), "d")}
                  </Box>
                </Stack>
              </Stack>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                <AnimatePresence>
                  {isLoaded ? (
                    <Stack
                      direction="row"
                      component={motion.div}
                      alignItems="center"
                      variants={variant1}
                      initial={"initial"}
                      animate={"animate"}
                      gap={2}
                    >
                      <motion.span variants={variant1}>
                        {new Intl.NumberFormat().format(Math.floor(stats.previousSevenDaysTotal))}
                      </motion.span>
                      <motion.span
                        variants={variant1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          userSelect: "none",
                        }}
                      ></motion.span>
                      <motion.span
                        variants={variant1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          userSelect: "none",
                        }}
                      >
                        {/* <ArrowUpward sx={{ fontSize: "0.8rem" }} /> */}
                        <motion.span
                          initial={{
                            fontSize: "1rem",
                            fontWeight: 400,
                          }}
                          animate={{
                            fontSize: "1.2rem",
                          }}
                        >
                          {/* {`${previousSevenDaysChange}%`} */}
                        </motion.span>
                      </motion.span>
                    </Stack>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </AnimatePresence>
              </Box>
              <SparkLineChart
                area
                slots={{
                  area: (props) => (
                    <HighlightArea
                      {...props}
                      trend={
                        previousSevenDaysChange < 3
                          ? trends[1]
                          : stats.previousSevenDaysTotal > stats.lastSevenDaysTotal
                            ? trends[2]
                            : trends[3]
                      }
                    />
                  ),
                }}
                margin={{ top: 4, left: 4, right: 4, bottom: 4 }}
                data={previousSevenDaysChartData.map((data) => data.count)}
                yAxis={{
                  domainLimit: "strict",
                }}
                xAxis={{
                  dataKey: "date",
                  data: stats.previousWeek.map((date) => format(date, "EEE dd")),
                }}
                colors={[
                  previousSevenDaysChange < 3
                    ? trends[1].colors.primary
                    : stats.previousSevenDaysTotal > stats.lastSevenDaysTotal
                      ? trends[2].colors.primary
                      : trends[3].colors.primary,
                ]}
                height={60}
              />
            </Stack>
          </Card>
          <Card sx={{ minWidth: "225px", p: 2, position: "relative" }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack sx={{ fontSize: "1.4rem", color: "primary.main" }}>
                  Last 7 Days
                  <Box sx={{ fontSize: "0.8rem", color: "grey.600" }}>
                    {format(subDays(new Date(), 7), "MMM d")} - {format(new Date(), "d")}
                  </Box>
                </Stack>
              </Stack>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                <AnimatePresence>
                  {isLoaded ? (
                    <Stack
                      direction="row"
                      component={motion.div}
                      alignItems="center"
                      variants={variant1}
                      initial={"initial"}
                      animate={"animate"}
                      gap={2}
                    >
                      <motion.span variants={variant1}>
                        {new Intl.NumberFormat().format(Math.floor(stats.lastSevenDaysTotal))}
                      </motion.span>
                      <motion.span
                        variants={variant1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          userSelect: "none",
                        }}
                      ></motion.span>
                      <motion.span
                        variants={variant1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          userSelect: "none",
                        }}
                      >
                        {lastSevenDaysChange > 0 ? (
                          <ArrowUpward sx={{ color: "green", fontSize: "0.9rem" }} />
                        ) : (
                          <ArrowDownward sx={{ color: "#f43f5e", fontSize: "0.9rem" }} />
                        )}
                        <motion.span
                          initial={{
                            fontSize: "1rem",
                            fontWeight: 400,
                          }}
                          animate={{
                            fontSize: "1.2rem",
                          }}
                        >
                          {`${Math.abs(lastSevenDaysChange)}%`}
                        </motion.span>
                      </motion.span>
                    </Stack>
                  ) : (
                    <Skeleton variant="text" />
                  )}
                </AnimatePresence>
              </Box>
              <SparkLineChart
                area
                slots={{
                  area: (props) => (
                    <HighlightArea
                      {...props}
                      trend={
                        lastSevenDaysChange < 3
                          ? trends[1]
                          : stats.lastSevenDaysTotal > stats.previousSevenDaysTotal
                            ? trends[2]
                            : trends[3]
                      }
                    />
                  ),
                }}
                margin={{ top: 4, left: 4, right: 4, bottom: 4 }}
                data={lastSevenDaysChartData.map((data) => data.count)}
                yAxis={{
                  domainLimit: "strict",
                  disableTicks: true,
                }}
                xAxis={{
                  dataKey: "date",
                  data: stats.lastWeek.map((date) => format(date, "EEE dd")),
                }}
                colors={[
                  lastSevenDaysChange < 3
                    ? trends[1].colors.primary
                    : stats.lastSevenDaysTotal > stats.previousSevenDaysTotal
                      ? trends[2].colors.primary
                      : trends[3].colors.primary,
                ]}
                height={60}
              />
            </Stack>
          </Card>
        </Box>

        <Box
          sx={{
            fontSize: "1.8rem",
            color: "grey.700",
            fontWeight: 500,
            letterSpacing: "-0.05rem",
          }}
        >
          Distance
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 4,
          }}
        >
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Box sx={{ fontSize: "1.4rem", color: "primary.main" }}>Total</Box>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                {isLoaded ? (
                  `${new Intl.NumberFormat().format(Math.floor(stats.totalDistance))} ft`
                ) : (
                  <Skeleton variant="text" />
                )}
              </Box>
            </Stack>
          </Card>
          <Card sx={{ minWidth: "225px", p: 2 }}>
            <Stack sx={{ gap: 1, justifyContent: "space-between", height: "100%" }}>
              <Box sx={{ fontSize: "1.4rem", color: "primary.main" }}>Average</Box>
              <Box sx={{ fontSize: "2.2rem", color: "grey.800", fontWeight: 600 }}>
                {isLoaded ? (
                  `${new Intl.NumberFormat().format(Math.floor(stats.totalDistance / stats.total))} ft`
                ) : (
                  <Skeleton variant="text" />
                )}
              </Box>
            </Stack>
          </Card>
        </Box>
      </Stack>
    </Container>
  );
}

export const HighlightArea = (props: AnimatedAreaProps & { trend: Trend }) => {
  const { ownerState, trend, ...rest } = props;
  return <path {...rest} fill={trend.colors.muted} opacity={0.8} />;
};

// ["#ecfdf5","#eef2ff"]

type Trend = {
  trend: string;
  colors: {
    primary: string;
    muted: string;
  };
};
