// import { UnityCanvas } from '~/components/simulator/UnityCanvas.client'
import { getStorage, ref, uploadBytesResumable, type UploadTask } from "firebase/storage";
import { fromEvent, Subject } from "rxjs";
import { WorkerClipData } from "@tdisc/video/dist/src/types/worker";

const VIDEO_CODEC = "avc1.640032";

export const uploadQueue$ = new Subject<WorkerClipData>();
export const pauseUpload$ = new Subject<boolean>();
export const devicesChanged$ = fromEvent(navigator.mediaDevices, "devicechange");

export const requestRecordingPermission = async () => {
  try {
    const permission = await navigator.mediaDevices.getUserMedia({ video: true });

    permission.getVideoTracks().forEach((track) => {
      track.stop();
    });

    return permission.getVideoTracks().at(0)?.getSettings().deviceId;
  } catch (error) {
    switch ((error as Error).name) {
      case "NotAllowedError":
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#notallowederror
        // The user has denied permission to use the media device.
        throw "User denied access to video device.";
      case "NotFoundError":
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#notfounderror
        // No media tracks of the requested type were found.
        throw "No video input devices found.";
      case "NotReadableError":
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#notreadableerror
        // The media device is already in use by another application.
        throw "Video device is currently in use.";
      case "OverconstrainedError":
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#overconstrainederror
        // The constraints specified cannot be satisfied by any available devices.
        throw "Constraints cannot be satisfied by available video devices.";
      case "SecurityError":
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#securityerror
        // Access to the media device is blocked due to security reasons.
        throw "Access to video device is blocked for security reasons.";
      case "TypeError":
        // The constraints object is empty or has invalid properties.
        throw "Invalid constraints provided for video device.";
      default:
        // An unknown error occurred.
        throw "An unknown error occurred while accessing video device.";
    }
  }
};

export const getDevices = async (): Promise<MediaDeviceInfo[]> => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(
    (device) => device.kind === "videoinput" && device.deviceId !== "",
  );

  return videoDevices;
};

export const createVideoUploadTask = ({
  userId,
  filename,
  throwId,
  data,
}: {
  userId?: string;
  filename: string;
  data: Blob | Uint8Array | ArrayBuffer;
  throwId: string;
}) => {
  const storageRef = ref(getStorage(), `throw-video/${userId}/${throwId}/${filename}.mp4`);
  return uploadBytesResumable(storageRef, data, {
    contentType: `video/mp4, codecs=${VIDEO_CODEC}`,
    cacheControl: "public, max-age=31536000, immutable",
  });
};

export const createThumbnailUploadTask = ({
  userId,
  throwId,
  filename,
  data,
}: {
  userId?: string;
  throwId?: string;
  filename: string;
  data: Blob | Uint8Array | ArrayBuffer;
}): UploadTask => {
  const storageRef = ref(getStorage(), `throw-video/${userId}/${throwId}/${filename}.jpg`);
  return uploadBytesResumable(storageRef, data, {
    contentType: `image/jpeg`,
    cacheControl: "public, max-age=31536000, immutable",
  });
};

export const pauseUploads = () => {
  pauseUpload$.next(true);
};

export const resumeUploads = () => {
  pauseUpload$.next(false);
};

export const generateVideoOptions = async (stream: MediaStream) => {
  const videoTrack = stream.getVideoTracks()[0];
  const constraints: MediaTrackConstraints[] = [
    { frameRate: 30, width: 1280, height: 720 },
    { frameRate: 60, width: 1920, height: 1080 },
    { frameRate: 120, width: 1920, height: 1080 },
  ];

  const supportedConstraints: MediaTrackConstraints[] = [];

  for (const constraint of constraints) {
    try {
      await videoTrack.applyConstraints(constraint);
      supportedConstraints.push(constraint);
    } catch (error) {
      console.error(error);
      console.table(constraint);
    }
  }

  return supportedConstraints;
};
