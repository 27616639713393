import Simulator from "../Simulator";
import { useUser } from "../../hooks/useUser";
import { useUnityContext } from "@/hooks/useUnityContext";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { getUserThrowSummaries } from "@/firebase/collections";
import { limit, orderBy, query } from "firebase/firestore";
import TagManager from "@/components/TagManager";
import { useGlobal } from "@/components/providers/GlobalProvider";
import { useLatestThrowsInQueue } from "@/latestDashboard";

function OnlyUnityRoute() {
  const { setLatestThrowId: setThrow } = useGlobal();
  const [{ userId }] = useUser();
  const unityContext = useUnityContext({
    buildNumber: process.env.SIMULATOR_VERSION,
  });
  const q1 = getUserThrowSummaries(userId);
  const filters = [];
  filters.push(limit(1));
  filters.push(orderBy("throwTime", "desc"));
  const q = q1 ? query(q1, ...filters) : null;
  const [docs, isLoading, error] = useCollectionData(q);
  useLatestThrowsInQueue(docs);

  const flights = docs?.length ? docs : [];

  return <Simulator context={unityContext} mode="single" userId={userId} flights={flights} />;
}

export default OnlyUnityRoute;
