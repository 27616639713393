import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  type TypographyProps,
} from "@mui/material";
import { shouldHideNav } from "@/components/layout/Navbar";
import { useMatches } from "react-router-dom";

// feel free to revert to that stack that was here, this is just what i touched up last week without seeing the earlier changes
export function Footer(props: TypographyProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      pt={4}
      pb={4}
      gap={1}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[200],
        minHeight: { mobile: "100px", tablet: "275px" },
      }}
    >
      <Container>
        <Stack
          sx={{
            display: "grid",
            gridTemplateColumns: { mobile: "1fr", tablet: "1fr 1fr" },
            gridTemplateRows: { tablet: "2fr 1fr" },
            gridAutoRows: { mobile: "auto" },
            gap: { mobile: 2 },
            width: "100%",
          }}
          gap={1}
        >
          <Stack gap={0.5} justifyContent={"flex-start"}>
            <Box
              component="img"
              src={"https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDisc_Logo_mono.svg"}
              alt="TechDisc Logo"
              width={isMobile ? "64px" : "100px"}
              sx={{
                filter: "invert(65%)",
              }}
            />
          </Stack>

          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: { tablet: "repeat(2, minmax(200px, 1fr))" },
            }}
          >
            <Stack>
              <List>
                <ListItem>
                  <Typography
                    variant="body1"
                    color={(theme) => theme.palette.grey[800]}
                    fontWeight={"medium"}
                    sx={{ textDecoration: "none" }}
                  >
                    TechDisc
                  </Typography>
                </ListItem>
                <Typography variant="body2">
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://shop.techdisc.com/products/techdisc-1"
                      sx={{ textDecoration: "none" }}
                    >
                      Shop
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href={"/stores"} sx={{ textDecoration: "none" }}>
                      Find a Store
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href={`/live`} sx={{ textDecoration: "none" }}>
                      Recent Events
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      color="primary"
                      href="https://shop.techdisc.com/pages/contact"
                      sx={{ textDecoration: "none" }}
                    >
                      Contact Us
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      color="primary"
                      href="https://techdisc.freshdesk.com/support/solutions"
                      sx={{ textDecoration: "none" }}
                    >
                      FAQ
                    </Link>
                  </ListItem>
                </Typography>
              </List>
            </Stack>
            <Stack>
              <List>
                <ListItem>
                  <Typography
                    variant="body1"
                    color={(theme) => theme.palette.grey[800]}
                    fontWeight={"medium"}
                  >
                    Disclaimer
                  </Typography>
                </ListItem>
                <Typography variant="body2">
                  <ListItem>
                    <Link target="_blank" href="/privacy-policy" sx={{ textDecoration: "none" }}>
                      Privacy Policy
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="/terms" sx={{ textDecoration: "none" }}>
                      Terms of Service
                    </Link>
                  </ListItem>
                </Typography>
              </List>
            </Stack>
          </Stack>

          <Stack>
            <Button
              sx={{
                width: "fit-content",
                px: 1,
                minWidth: 0,
                color: (theme) => theme.palette.grey[700],
              }}
              component={Link}
              variant="link"
              target="_blank"
              href="https://www.instagram.com/tech_disc/"
              title="Instagram"
            >
              <InstagramIcon fontSize="large" />
            </Button>
          </Stack>

          <Stack direction="row">
            <Stack gap={1} px={2}>
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.grey[800] }}
                {...props}
              >
                {`Copyright © ${new Date().getFullYear()} TechDisc, Inc.`}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.grey[800] }}
                {...props}
              >
                {"P.O. Box 11464 ; Overland Park, KS 66207"}
              </Typography>
              <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[600] }}>
                This site is protected by reCAPTCHA and the Google{" "}
                <Link
                  target="_blank"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                  href="https://policies.google.com/privacy"
                >
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  target="_blank"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                  href="https://policies.google.com/terms"
                >
                  Terms of Service
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
