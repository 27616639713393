import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { t } from "i18next";
import * as React from "react";
import { useGlobal } from "../components/providers/GlobalProvider";
import { Handedness } from "../model/UserSettings";
import { Device } from "../model/device";
import { FlightNumbers, ThrowSummary } from "../model/throwSummary";
import { getThrowName, getVerboseThrowStyle } from "../utils/throw";
import { FlightNumberBoxes } from "./FlightNumberChooser";
import Title from "./Title";
import { getDeviceColor } from "./dashboardUtils";

// TODO thinking this ought to live elsewhere, it's used in throwsetinternal too
export const InfoTooltip = (props: { text: string; numbers?: FlightNumbers }) => {
  return (
    <Tooltip
      sx={{
        "& .MuiTooltip-tooltip": {
          width: "500px !important",
        },
      }}
      enterDelay={500}
      title={
        props.numbers ? (
          <FlightNumberBoxes includeWeight={false} flight_numbers={props.numbers} />
        ) : (
          props.text
        )
      }
    >
      <sup>
        <IconButton size="small">
          <HelpOutlineIcon sx={{ fontSize: "small" }} />
        </IconButton>
      </sup>
    </Tooltip>
  );
};

export default function DashboardTable(props: {
  throws: ThrowSummary[];
  devices: Map<string, Device>;
  userId: string;
  isThrowSet?: boolean;
}) {
  const { throws, devices, userId, isThrowSet } = props;
  const { prefersMetric } = useGlobal();

  let renderDisc = false;
  if (throws.length && devices.size) {
    const first = throws[0];
    const allSame = throws.reduce((prev, cur) => prev && cur.deviceId == first.deviceId, true);
    renderDisc = !allSame;
  }
  const { isInspectRoute, userSettings } = useGlobal();
  const handedness = userSettings?.handedness ?? Handedness.RIGHT;

  const createThrowHref = (throwId: string) => {
    return isInspectRoute ? `/admin/inspect/${userId}/throws/${throwId}` : `/throws/${throwId}`;
  };

  return (
    <React.Fragment>
      <Title variant="secondary">{isThrowSet ? "Throws" : "Recent Throws"}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {renderDisc && <TableCell>Disc</TableCell>}
            <TableCell>Type</TableCell>
            <TableCell>
              Speed {`(${prefersMetric ? t("units.kmh.upper") : t("units.mph.upper")})`}
            </TableCell>
            <TableCell>Hyzer (DEG)</TableCell>
            <TableCell>Spin (RPM)</TableCell>
            <TableCell>Nose (DEG)</TableCell>
            <TableCell>Launch (DEG)</TableCell>
            <TableCell>Wobble (DEG)</TableCell>
            <TableCell>Adv Ratio (%)</TableCell>
            <TableCell>Distance {prefersMetric ? "(M)" : "(FT)"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {throws.map((throwSummary) => (
            <TableRow key={throwSummary.id}>
              {renderDisc && (
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        getDeviceColor(devices, throwSummary.deviceId) ?? theme.palette.grey[400],
                      borderRadius: "100%",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </TableCell>
              )}
              <TableCell style={{ whiteSpace: "nowrap" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <a
                    // target="_blank"
                    href={createThrowHref(throwSummary.id)}
                    rel="noreferrer"
                    style={{ marginRight: "10px" }}
                  >
                    {`${getVerboseThrowStyle(throwSummary, throwSummary.handedness ?? handedness)} ${getThrowName(throwSummary, throwSummary.handedness ?? handedness)}`}
                  </a>
                </div>
              </TableCell>
              <TableCell>{throwSummary.speedMph?.toFixed(1)}</TableCell>
              <TableCell>{throwSummary.hyzerAngle?.toFixed(1)}</TableCell>
              <TableCell>{throwSummary.spinRpm?.toFixed(0)}</TableCell>
              <TableCell>{throwSummary.noseAngle?.toFixed(1)}</TableCell>
              <TableCell>{throwSummary.uphillAngle?.toFixed(1)}</TableCell>
              <TableCell>{throwSummary.offAxisDegrees?.toFixed(1)}</TableCell>
              <TableCell>{throwSummary.advanceRatio?.toFixed(0)}</TableCell>
              <TableCell>
                <Stack direction="row" gap={1}>
                  <Typography variant="body2" sx={{ minWidth: "50px" }}>
                    {throwSummary.estimatedFeet?.toFixed(2)}
                  </Typography>
                  <InfoTooltip
                    text={"Simulated Flight"}
                    numbers={throwSummary.estimatedFlightNumbers}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
