import { getUserAdminDoc } from "@/firebase/docs";
import { getDoc, getDocs } from "firebase/firestore";

import {
  getAdminSimulatorCourseCollection,
  getAdminSimulatorLayoutCollection,
  getAdminSimulatorPermissions,
  getAdminUserRolesCollection,
} from "@/firebase/collections";
import type { Entitlement } from "@/stripe";
import {
  LayoutType,
  PlayableLayout,
  SimulatorCourseDocument,
  SimulatorCourseHole,
  SimulatorLayout,
} from "@/types/simulator";

export const getUserPermissions = async (userId?: string, entitlements?: Entitlement[]) => {
  try {
    const userRolesDocs = await getDocs(getAdminUserRolesCollection());
    const userRoles = userRolesDocs.docs.map((doc) => doc.data());
    const externalRoles = userRoles.filter((role) => role.external);
    const courseIds: Set<string> = new Set();
    const layoutIds: Set<string> = new Set();
    const simulatorPermissionIds: Set<string> = new Set(userId ? ["public", "free"] : ["public"]);

    // If the user is logged in, we need to check their roles for additional permissions
    if (userId) {
      const userAdminDoc = await getDoc(getUserAdminDoc(userId));

      const { roles: assignedRoles = [], permissions } = userAdminDoc.exists()
        ? userAdminDoc.data()
        : { roles: [], permissions: {} };
      const assignedExternalRoles = externalRoles.filter((role) =>
        entitlements?.includes(role.claimToken),
      );

      const roles = [
        ...assignedRoles.map((roleId) => userRoles.find((role) => role.id === roleId)),
        ...assignedExternalRoles,
      ];

      for (const role of roles) {
        const roleBasedPermissions = role?.permissions?.simulator ?? [];
        for (const permissionId of roleBasedPermissions) {
          simulatorPermissionIds.add(permissionId);
        }
      }

      const userPermissions = permissions?.simulator ?? [];

      for (const permissionId of userPermissions) {
        simulatorPermissionIds.add(permissionId);
      }
    }

    const simulatorCoursesDocs = await getDocs(getAdminSimulatorCourseCollection());
    const simulatorPermissionsDocs = await getDocs(getAdminSimulatorPermissions());
    const simulatorLayoutsDocs = await getDocs(getAdminSimulatorLayoutCollection());

    const simulatorCourses = simulatorCoursesDocs.docs.map((doc) => doc.data());
    const simulatorPermissions = simulatorPermissionsDocs.docs.map((doc) => doc.data());
    const simulatorLayouts = simulatorLayoutsDocs.docs.map((doc) => doc.data());

    const permissions = Array.from(simulatorPermissionIds).map((id) =>
      simulatorPermissions.find((permission) => permission.id === id),
    );
    // Get the courses that the user is allowed to use, always including the public permissions
    for (const permission of permissions) {
      if (permission) {
        for (const courseId of permission.allowedCourses) {
          courseIds.add(courseId);
        }
        if (permission.allowedLayouts) {
          for (const layoutId of permission.allowedLayouts) {
            layoutIds.add(layoutId);
          }
        }
      }
    }

    // Format the course data to include the display name
    const courseData = Array.from(courseIds)
      .map((courseId) => {
        const course = simulatorCourses.find((course) => course.id === courseId);
        if (!course) {
          console.error(`Course not found: ${courseId}`);
          console.error(
            `Permission with course was `,
            permissions.find((p) => p?.allowedCourses.includes(courseId)),
          );
          return undefined;
        }
        return {
          ...course,
          id: courseId,
          displayName: course?.name ?? course?.displayName,
        };
      })
      .filter((a) => !!a);

    const layoutData = Array.from(layoutIds)
      .map((layoutId) => {
        const layout = simulatorLayouts.find((layout) => layout.id === layoutId);
        if (!layout) {
          console.error(`Layout not found: ${layoutId}`);
          return undefined;
        }

        const courses = layout.holes.map((holeRef) => {
          let layoutType: LayoutType | undefined = undefined;
          if (holeRef?.layoutType !== undefined) {
            layoutType = holeRef.layoutType;
          } else if (holeRef?.playableLayouts?.length) {
            layoutType = holeRef.playableLayouts[0].layoutType;
          }
          const course = simulatorCourses.find((course) => course.id === holeRef.courseId);
          if (!course) {
            console.error(`Course not found: ${holeRef.courseId} as part of layout ${layoutId}`);
            return undefined;
          }
          const courseHole = course.holes.find(
            (h) => holeRef.holeId === h.id || holeRef.holeId === h.assetGuid,
          );
          if (!courseHole) {
            console.error(
              `Course hole not found: ${holeRef.holeId} as part of course ${holeRef.courseId} from layout ${layoutId}`,
            );
            return undefined;
          }
          const match = courseHole.playableLayouts?.find(
            (l: PlayableLayout) =>
              l.layoutType === layoutType ||
              (holeRef.layoutName && l.displayName === holeRef.layoutName),
          );
          const playableLayout =
            match ?? courseHole.playableLayouts?.[0] ?? holeRef.playableLayouts?.[0];

          const { nickname, ...courseHoleNoName } = courseHole;
          const courseHoleWithLayout: SimulatorCourseHole = {
            ...courseHoleNoName,
            displayName: courseHole.nickname ?? courseHole.displayName,
            playableLayouts: [(playableLayout ?? { layoutType: 0 }) as PlayableLayout],
          };
          const { name, ...courseNoName } = course;
          return {
            ...courseNoName,
            displayName: course.name ?? course.displayName,
            holes: [courseHoleWithLayout],
          };
        });
        if (courses.some((c) => !c)) {
          console.error(`Failed to find all courses for layout ${layoutId}.`);
          return undefined;
        }

        const { name, holes, ...rest } = layout;
        return {
          ...rest,
          displayName: layout?.name,
          courses,
        };
      })
      .filter((a) => !!a);

    return {
      allowedCourses: courseData,
      allowedLayouts: layoutData,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};
