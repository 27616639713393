import { addDoc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import { getUserDiscPresets } from "../../firebase/collections";
import { getCuratedDiscPreset, getUserDiscPreset } from "../../firebase/docs";
import { DiscClass, DiscPreset, DiscPresetDocumentData } from "../../model/discs";

export const createUserDiscPreset = async (userId: string, discPreset: DiscPresetDocumentData) => {
  const docRef = getUserDiscPresets(userId);
  if (docRef) {
    const doc = await addDoc(docRef, discPreset);
    return doc.id;
  }
  return null;
};
export const saveUserDiscPreset = async (userId: string, discPreset: DiscPresetDocumentData) => {
  const docRef = getUserDiscPreset(userId, discPreset.id);
  if (docRef) {
    await setDoc(docRef, discPreset, {
      merge: true,
    });
  }
  return true;
};
export const deleteUserDiscPreset = async (userId: string, discPresetId: string) => {
  const docRef = getUserDiscPreset(userId, discPresetId);
  if (docRef) {
    await deleteDoc(docRef);
  }
  return true;
};

export const copyDiscPresetToGlobal = async (userId: string, discPresetId: string) => {
  const curatedRef = getCuratedDiscPreset(discPresetId);
  const userDisc = getUserDiscPreset(userId, discPresetId);
  if (curatedRef && userDisc) {
    // get data from user disc anc copy it to curated disc
    const data = await getDoc(userDisc);
    if (data.exists()) {
      await setDoc(curatedRef, data.data() as DiscPreset, {
        merge: true,
      });
    }
  }
  return true;
};
