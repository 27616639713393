import { useUser } from "@/hooks/useUser";
import { startEventQueue } from "@/utils/logging";
import { Alert, Container, Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MotionConfig } from "framer-motion";
import LogRocket from "logrocket";
import { useEffect } from "react";
import { Outlet, Params, ScrollRestoration, UIMatch, useMatches } from "react-router-dom";
import { ErrorBoundary } from "../ErrorBoundary";
import { useGlobal } from "../providers/GlobalProvider";
import { Medium, Text } from "../Text";
import { Footer } from "./Footer";
import Navbar from "./Navbar";
import UnauthenticatedBanner from "./UnauthenticatedBanner";
import { toast, Toaster } from "sonner";

function shouldHideNav(routes: UIMatch[]) {
  return routes.some((route) => ["/unityMobileDashboard"].includes(route.pathname));
}

function getErrorBoundary() {
  return (
    <ErrorBoundary
      eventName="uncaught_error"
      fallback={
        <Container
          sx={{
            my: 8,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
          maxWidth="sm"
        >
          <Medium size={36} color="secondary.main" spacing="dense" sx={{ lineHeight: "1.2" }}>
            We apologize for the inconvenience.
          </Medium>
          <Text size={20} color="secondary.dark" spacing="loose">
            An unexpected error has occurred.
          </Text>
          <Alert severity="success">A report has been sent to our team.</Alert>
          <Text size={16} color="secondary.dark" spacing="loose">
            Please try refreshing the page or{" "}
            <Box
              component={Link}
              href="https://shop.techdisc.com/pages/contact"
              sx={{ display: "contents" }}
            >
              contact support
            </Box>{" "}
            if the problem persists.
          </Text>
        </Container>
      }
    >
      <Outlet />
      {/* @ts-expect-error */}
      <Toaster richColors position="top-center" offset="72px" />
    </ErrorBoundary>
  );
}

function getContent() {
  return (
    <Stack
      sx={{
        minHeight: "calc(100vh - 48px)",
        backgroundColor: (theme) =>
          theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
        overflow: "auto",
        position: "relative",
      }}
      component="main"
    >
      {getErrorBoundary()}
    </Stack>
  );
}

export default function Layout() {
  const { latestThrowId } = useGlobal();
  const [{ user }] = useUser();

  const matches: UIMatch<unknown, { title: string | ((params: Params) => string) }>[] =
    useMatches();

  useEffect(() => {
    const match = matches.reduceRight((acc, match) => {
      return acc ? acc : match.handle?.title;
    }) ?? { handle: { title: "" } };
    if (match?.handle?.title) {
      if (typeof match?.handle?.title === "function") {
        document.title = `${match?.handle?.title(match.params)} - TechDisc`;
      } else {
        document.title = `${match?.handle?.title} - TechDisc`;
      }
    }
  }, [matches]);

  useEffect(() => {
    const unsubscribe = startEventQueue();

    return () => unsubscribe.unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      LogRocket.identify(user?.uid, {
        name: user?.displayName ?? "",
        email: user?.email ?? "",
      });
    }
  }, [user]);
  const shouldHideNavBar = shouldHideNav(matches);

  if (shouldHideNavBar) {
    return getContent();
  }

  return (
    <MotionConfig reducedMotion="user">
      <Navbar latestThrowId={latestThrowId} />
      <UnauthenticatedBanner />
      <ScrollRestoration />
      {getContent()}
      <Footer />
    </MotionConfig>
  );
}
