import { CustomEventName, getAnalytics, logEvent } from "firebase/analytics";
import React, { ErrorInfo } from "react";
import { firebaseApp } from "../firebaseConfig";
import { useUser } from "../hooks/useUser";
import { useMatches } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LogRocket from "logrocket";

export interface ErrorBoundaryProps {
  eventName: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
export interface ErrorBoundaryState {
  hasError: boolean;
}

class CatchError<
  TProps extends ErrorBoundaryProps,
  TState extends ErrorBoundaryState,
> extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { hasError: false } as TState;
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { componentStack, digest } = errorInfo;
    LogRocket.captureException(error, {
      extra: { componentStack: componentStack ?? "", digest: digest ?? "" },
    });

    logEvent(
      getAnalytics(firebaseApp),
      this.props.eventName as CustomEventName<TProps["eventName"]>,
      {
        error: error.name,
        message: error.message,
        errorInfo: errorInfo.componentStack,
      },
    );
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children, ...rest } = props;
  const [{ userId, trueUserId, externalUserId }] = useUser();
  const matches = useMatches();

  const errorInfo = {
    userId,
    route: matches.at(-1)?.pathname,
    ...rest,
  };

  if (userId !== trueUserId) {
    // @ts-expect-error
    errorInfo.trueUserId = trueUserId;
  }

  if (userId !== externalUserId) {
    // @ts-expect-error
    errorInfo.externalUserId = externalUserId;
  }

  return <CatchError {...errorInfo}>{children}</CatchError>;
};
