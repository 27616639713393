import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export interface GroupSidebarItemProps {
  label: ReactNode;
  to?: string;
  loading?: boolean;
  isSelected?: boolean;
}

export default function GroupSidebarItem(props: GroupSidebarItemProps) {
  const { label, to, loading, isSelected } = props;
  const content = (
    <Stack
      component={motion.button}
      direction={"row"}
      alignItems="center"
      justifyContent="center"
      disabled={loading}
      sx={{
        height: "100%",
        width: "100%",
        px: { mobile: 1, md: 2 },
        py: { mobile: 0.5, md: 1 },
        borderRadius: "96px",
        backgroundColor: isSelected ? "white" : "grey.50",
        border: isSelected ? "1px solid" : "1px solid",
        borderColor: isSelected ? "primary.main" : "grey.400",
        minHeight: "40px",
        color: isSelected ? "primary.main" : "grey.600",
        fontWeight: isSelected ? 500 : 400,
        "&:hover": {
          color: !isSelected && "grey.600",
          backgroundColor: !isSelected && "white",
          borderColor: !isSelected && "grey.400",
        },
      }}
    >
      {label}
    </Stack>
  );

  return to ? (
    <Box
      component={Link}
      sx={{
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
        width: "100%",
      }}
      to={to}
      relative="route"
    >
      {content}
    </Box>
  ) : (
    content
  );
}
