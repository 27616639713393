import { simulatorPermissionsConverter } from "@/firebase/converters/simulatorPermissions";
import { userAdminConverter } from "@/firebase/converters/userAdmin";
import type { SimulatorPermission } from "@/types/simulator";
import type { UserAdminInfo } from "@/types/user";
import { UserProfile } from "firebase/auth";
import { DocumentReference, doc, collection, getFirestore } from "firebase/firestore";
import { DiscPreset, DiscPresetDocumentData } from "../model/discs";
import { ThrowSummary } from "../model/throwSummary";
import {
  getAdminSimulatorPermissions,
  getAnalysisSetCollection,
  getUserAdminCollection,
  getUserDiscPresets,
  getUserStockShots,
  getUserThrowSummaries,
  getUsersCollection,
} from "./collections";
import { ThrowAnalysis, throwAnalysisConverter } from "./converters/analysisSet";
import { discPresetConverter } from "./converters/discPreset";
import { StockShotDocumentData, stockShotConverter } from "./converters/stockShot";
import { throwSummaryConverter } from "./converters/throwSummary";
import { firebaseApp } from "@/firebaseConfig";
import { DISC_PRESETS_COLLECTION_PATH } from "@/firebase/paths";

export function getUserDoc(uid: string): DocumentReference<UserProfile> | null {
  return uid ? doc(getUsersCollection(), uid) : null;
}

export function getUserAdminDoc(uid: string): DocumentReference<UserAdminInfo> {
  return doc(getUserAdminCollection(), uid).withConverter(userAdminConverter);
}

export function getUserStockShot(
  uid: string,
  id?: string,
): DocumentReference<StockShotDocumentData> | null {
  const stockShotsRef = getUserStockShots(uid);
  return stockShotsRef && id ? doc(stockShotsRef, id).withConverter(stockShotConverter) : null;
}

export function getAnalysisSet(uid: string, id?: string): DocumentReference<ThrowAnalysis> | null {
  const analysisSetRef = getAnalysisSetCollection(uid);
  return analysisSetRef && id
    ? doc(analysisSetRef, id).withConverter(throwAnalysisConverter)
    : null;
}

export function getThrowSummary(uid: string, id?: string): DocumentReference<ThrowSummary> | null {
  const summariesRef = getUserThrowSummaries(uid);
  return summariesRef && id ? doc(summariesRef, id).withConverter(throwSummaryConverter) : null;
}

export function getUserDiscPreset(
  uid: string,
  discId?: string,
): DocumentReference<DiscPreset, DiscPresetDocumentData> | null {
  const discPresetsRef = getUserDiscPresets(uid);
  return discPresetsRef && discId
    ? doc(discPresetsRef, discId).withConverter(discPresetConverter)
    : null;
}

export function getCuratedDiscPreset(
  discId: string,
): DocumentReference<DiscPreset, DiscPresetDocumentData> {
  const curated = collection(getFirestore(firebaseApp), "curated-discs").withConverter(
    discPresetConverter,
  );

  return doc(curated, discId);
}

export function getAdminSimulatorPermission(
  permissionId: string,
): DocumentReference<SimulatorPermission> | null {
  return doc(getAdminSimulatorPermissions(), permissionId).withConverter(
    simulatorPermissionsConverter,
  );
}
