import React from "react";
import { FlightNumbers } from "../model/throwSummary";
import SimSlider, { SliderProps } from "./SimSlider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export function getSpeedProps(
  flight_numbers: FlightNumbers,
  setFlightState: (value: (prevState: any) => FlightNumbers) => void,
): SliderProps {
  return {
    title: "Speed",
    step: 0.5,
    values: [
      { value: 0, label: "" },
      { value: 1, label: "1" },
      { value: 3, label: "3" },
      { value: 5, label: "5" },
      { value: 7, label: "7" },
      { value: 9, label: "9" },
      { value: 11, label: "11" },
      { value: 14, label: "14" },
    ],
    value: flight_numbers.speed,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return {
          ...prevState,
          speed: value,
        };
      }),
  };
}

export function getWeightProps(
  flight_numbers: FlightNumbers,
  setFlightState: (value: (prevState: any) => FlightNumbers) => void,
): SliderProps {
  return {
    title: "Weight",
    step: 1,
    values: [
      { value: 135, label: "135" },
      { value: 155, label: "155" },
      { value: 165, label: "165" },
      { value: 175, label: "175" },
      { value: 180, label: "180" },
    ],
    value: (flight_numbers.weight || 0.175) * 1000,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return {
          ...prevState,
          weight: value / 1000.0,
        };
      }),
  };
}

export function getTurnProps(
  flight_numbers: FlightNumbers,
  setFlightState: (value: (prevState: any) => FlightNumbers) => void,
): SliderProps {
  return {
    title: "Turn",
    step: 0.25,
    values: [
      { value: -4, label: "-4" },
      { value: -3, label: "-3" },
      { value: -2, label: "-2" },
      { value: -1, label: "-1" },
      { value: -0.5, label: "-0.5" },
      { value: 0, label: "0" },
      { value: 0.5, label: "0.5" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3.0, label: "3.0" },
    ],
    value: flight_numbers.turn,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return {
          ...prevState,
          turn: value,
        };
      }),
  };
}

export function getGlideProps(
  flight_numbers: FlightNumbers,
  setFlightState: (value: (prevState: any) => FlightNumbers) => void,
): SliderProps {
  return {
    title: "Glide",
    values: [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
      { value: 6, label: "6" },
    ],
    value: flight_numbers.glide,
    handleChange: (e, value) =>
      setFlightState((prevState) => {
        return {
          ...prevState,
          glide: value,
        };
      }),
  };
}

const NumberBox = (props: { label: string; value: number; fractionDigits?: number }) => (
  <Paper sx={{ p: 0, textAlign: "center" }}>
    <Typography variant="subtitle1" sx={{ mb: 1 }}>
      {props.label}
    </Typography>
    <Typography variant="h6">{props.value?.toFixed(props.fractionDigits ?? 2)}</Typography>
  </Paper>
);

export function BoxRow(props: { flight_numbers: FlightNumbers; includeWeight?: boolean }) {
  const nums = props.flight_numbers;
  const size = 3;
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={size}>
        <NumberBox label="Speed" value={nums.speed} />
      </Grid>
      <Grid item xs={6} sm={size}>
        <NumberBox label="Glide" value={nums.glide} />
      </Grid>
      <Grid item xs={6} sm={size}>
        <NumberBox label="Turn" value={nums.turn} />
      </Grid>
      <Grid item xs={6} sm={size}>
        <NumberBox
          label="Fade"
          value={nums.fade || Math.max(0, nums.turn + Math.round(nums.speed) / 4 + 1 / 2)}
        />
      </Grid>
      {props.includeWeight && (
        <Grid item xs={6} sm={size}>
          <NumberBox label="Weight" value={(nums.weight ?? 0.175) * 1000} fractionDigits={0} />
        </Grid>
      )}
    </Grid>
  );
}

export function FlightNumberBoxes(props: {
  flight_numbers: FlightNumbers;
  includeWeight?: boolean;
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <BoxRow {...props} />
    </Paper>
  );
}

export function FlightNumberSliders(props: {
  flight_numbers: FlightNumbers;
  setFlightNumbers: (value: (prevState: any) => FlightNumbers) => void;
}) {
  const { flight_numbers, setFlightNumbers } = props;
  const speedProps = getSpeedProps(flight_numbers, setFlightNumbers);
  const glideProps = getGlideProps(flight_numbers, setFlightNumbers);
  const turnProps = getTurnProps(flight_numbers, setFlightNumbers);
  const weightProps = getWeightProps(flight_numbers, setFlightNumbers);
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography component="span" display="inline" variant="h5" sx={{ mb: 1 }}>
        {"Simulated Disc Numbers"}
      </Typography>
      <SimSlider {...speedProps} />
      <SimSlider {...glideProps} />
      <SimSlider {...turnProps} />
      <SimSlider {...weightProps} />
    </Paper>
  );
}
