import { useUser } from "@/hooks/useUser";
import {
  AdminPanelSettingsOutlined,
  Camera,
  Dashboard,
  Gamepad,
  LeaderboardOutlined,
  PersonSearchOutlined,
  PreviewOutlined,
  ReplayOutlined,
  ShoppingCartOutlined,
  Speed,
  Subscriptions,
  SummarizeOutlined,
  SvgIconComponent,
} from "@mui/icons-material";
import DatasetIcon from "@mui/icons-material/DatasetOutlined";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import {
  Box,
  Divider,
  Icon,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { addDays, isBefore } from "date-fns/esm";
import { useTranslation } from "react-i18next";
import RackIcon from "../icons/RackIcon";
import { useGlobal } from "../providers/GlobalProvider";
export type NavigationMenuProps = {
  menuRef: HTMLElement;
  onClose: () => void;
};

type NavigationItem = {
  label: string;
  icon: SvgIconComponent;
  href: string;
  authorized: boolean;
  deployedAt?: Date;
  beta?: boolean;
};

export default function NavigationMenu(props: NavigationMenuProps) {
  const { menuRef, onClose: handleClose } = props;
  const [{ user }] = useUser();
  const { isManufacturing, isManager, isPremiumStore, isStore, isAdmin, featureFlags } =
    useGlobal();
  const { t } = useTranslation();
  const theme = useTheme();
  const classicNavigationItems: NavigationItem[] = [
    {
      label: t("dashboard"),
      icon: Dashboard,
      href: "/",
      authorized: true,
    },
    {
      label: t("throw_capital_other"),
      icon: DatasetIcon,
      href: "/throws",
      authorized: true,
    },
    {
      label: t("throw_set_capital_other"),
      icon: TrendingUpOutlinedIcon,
      href: "/throws/sets",
      authorized: true,
    },
    {
      label: t("simulator_capital_one"),
      icon: MovieOutlinedIcon,
      href: "/simulator",
      authorized: true,
    },
  ];
  const videoNavigationItems: NavigationItem[] = [
    {
      label: t("video_capture"),
      icon: Camera,
      href: "/video/capture",
      authorized: !!featureFlags.video_capture,
      beta: true,
    },
    {
      label: t("video_review"),
      icon: PreviewOutlined,
      href: "/video/review",
      authorized: !!featureFlags.video_review && isAdmin,
      beta: true,
    },
  ];

  const playNavigationItems: NavigationItem[] = [
    {
      label: t("stockShots"),
      icon: RuleIcon,
      href: "/stock-shots",
      authorized: true,
      deployedAt: new Date("2024-12-11T20:44:28.420Z"),
    },
    {
      label: t("disc_capital_other"),
      icon: () => <RackIcon color={theme.palette.grey[600]} sx={{ fontSize: "16px" }} />,
      href: "/discs",
      authorized: true,
      deployedAt: new Date("2024-12-11T20:44:28.420Z"),
    },
    {
      label: t("stroke_play_capital"),
      icon: Gamepad,
      href: "/play/stroke",
      authorized: featureFlags.stroke_play,
      deployedAt: new Date("2024-12-11T20:44:28.420Z"),
    },
  ];

  const storeNavigationItems: NavigationItem[] = [
    {
      label: t("test_drive"),
      icon: Speed,
      href: "/test-drive",
      authorized: isStore,
    },
    {
      label: t("leaderboards"),
      icon: LeaderboardOutlined,
      href: "/leaderboards",
      authorized: isPremiumStore,
    },
  ];

  const internalNavigationItems = [
    {
      label: t("user_management"),
      icon: AdminPanelSettingsOutlined,
      href: "/admin/permissions",
      authorized: [isAdmin, isManager].some(Boolean),
    },
    {
      label: t("inspect_user"),
      icon: PersonSearchOutlined,
      href: "/admin/inspect",
      authorized: [isAdmin, isManager].some(Boolean),
    },
    {
      label: t("session_replay"),
      icon: ReplayOutlined,
      href: "/admin/replay",
      authorized: [isAdmin, isManager].some(Boolean),
    },
    {
      label: "Overview",
      icon: SummarizeOutlined,
      href: "/overview",
      authorized: [isAdmin, isManager, isManufacturing].some(Boolean),
    },
  ];

  const sx = {
    position: "relative",
    alignItems: "center",
    color: theme.palette.grey[800],
    width: "100%",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
    "&:visited": {
      alignItems: "center",
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
    "&:focus": {
      alignItems: "center",
      color: theme.palette.grey[800],
      textDecoration: "none",
    },
  };

  return (
    <Menu
      open={Boolean(menuRef)}
      onClose={handleClose}
      anchorEl={menuRef}
      sx={{ ".MuiList-root": { minWidth: "240px" } }}
    >
      {!user?.uid && (
        <MenuItem
          key="shop-link"
          component={Link}
          onClick={handleClose}
          target="_blank"
          href="https://shop.techdisc.com/"
          id="shop-link"
          variant="body1"
          sx={{ ...sx, display: "flex" }}
        >
          <ListItemIcon>
            <Icon
              sx={{ color: theme.palette.grey[600], mr: 0.5 }}
              fontSize="small"
              component={ShoppingCartOutlined}
            />
          </ListItemIcon>
          <ListItemText>{t("shop")}</ListItemText>
        </MenuItem>
      )}
      {!user?.uid && (
        <MenuItem
          key="plans-link"
          component={Link}
          onClick={handleClose}
          href="/plans"
          variant="body1"
          sx={{ ...sx, display: "flex" }}
        >
          <ListItemIcon>
            <Icon
              sx={{ color: theme.palette.grey[600], mr: 0.5 }}
              fontSize="small"
              component={Subscriptions}
            />
          </ListItemIcon>
          <ListItemText>{t("explorePlans")}</ListItemText>
        </MenuItem>
      )}
      {!user?.uid && <Divider key="divider-throws" />}
      {classicNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>
              {item.label}
              {item.deployedAt && isBefore(new Date(), addDays(item.deployedAt, 14)) && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(220, 252, 231, 0.65)",
                    border: "1px solid rgb(187, 247, 208)",
                    color: "rgb(21, 128, 61)",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  NEW
                </Box>
              )}
            </ListItemText>
          </MenuItem>
        ) : null,
      )}
      {videoNavigationItems.some((item) => item.authorized) && <Divider key="divider-video" />}
      {videoNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>
              {item.label}
              {item.deployedAt && isBefore(new Date(), addDays(item.deployedAt, 14)) && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(220, 252, 231, 0.65)",
                    border: "1px solid rgb(187, 247, 208)",
                    color: "rgb(21, 128, 61)",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  NEW
                </Box>
              )}
              {item.beta && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "#ede9fe",
                    border: "1px solid #a5b4fc",
                    color: "#4338ca",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  BETA
                </Box>
              )}
            </ListItemText>
          </MenuItem>
        ) : null,
      )}
      <Divider key="divider-play" />
      {playNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>
              {item.label}
              {item.deployedAt && isBefore(new Date(), addDays(item.deployedAt, 14)) && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(220, 252, 231, 0.65)",
                    border: "1px solid rgb(187, 247, 208)",
                    color: "rgb(21, 128, 61)",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  NEW
                </Box>
              )}
            </ListItemText>
          </MenuItem>
        ) : null,
      )}
      {isStore && <Divider key="divider-store" />}
      {storeNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>
              {item.label}
              {item.deployedAt && isBefore(new Date(), addDays(item.deployedAt, 14)) && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(220, 252, 231, 0.65)",
                    border: "1px solid rgb(187, 247, 208)",
                    color: "rgb(21, 128, 61)",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  NEW
                </Box>
              )}
            </ListItemText>
          </MenuItem>
        ) : null,
      )}
      {(isAdmin || isManager) && <Divider key="divider-admin" />}
      {internalNavigationItems.map((item) =>
        item.authorized ? (
          <MenuItem key={item.href} sx={sx} component={Link} onClick={handleClose} href={item.href}>
            <ListItemIcon>
              <Icon
                sx={{ color: theme.palette.grey[600], mr: 0.5 }}
                fontSize="small"
                component={item.icon}
              />
            </ListItemIcon>
            <ListItemText>
              {item.label}
              {item.deployedAt && isBefore(new Date(), addDays(item.deployedAt, 14)) && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(220, 252, 231, 0.65)",
                    border: "1px solid rgb(187, 247, 208)",
                    color: "rgb(21, 128, 61)",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  NEW
                </Box>
              )}
              {item.beta && (
                <Box
                  sx={{
                    position: "absolute",
                    boxSizing: "content-box",
                    top: "50%",
                    right: 8,
                    transform: "translateY(-50%)",
                    backgroundColor: "#e0e7ff",
                    border: "1px solid #818cf8",
                    color: "#4338ca",
                    fontWeight: 500,
                    borderRadius: "2px",
                    fontSize: "0.7rem",
                    padding: "2px 4px",
                  }}
                >
                  BETA
                </Box>
              )}
            </ListItemText>
          </MenuItem>
        ) : null,
      )}
    </Menu>
  );
}
